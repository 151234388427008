.admin-login{
    height: 400px;
    position: absolute;
    background-color: black;
    width: 90%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login-title{
    color: white;
    text-align: center;
    padding-top: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.login-line{
    height: 1px;
    border: 1px solid white;
    width: 50px;
    margin-top: 15px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.login-content{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    padding: 40px 10px 10px;
    text-align: center;
    top: 70%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}

.padding-label{
    padding: 10px;
}

.padding-label1{
    padding: 10px 38px 10px 10px;
}


.margin-label{
    margin: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.margin-label-submit{
    margin: 10px 5px 5px 11px;
    padding: 5px 84px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    background-color: white;
    border-radius: 5px;
}