.heading-soon{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
    width: 380px
}

.center-soon{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.button-soon{
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border: 2px solid black;
    background-color: white;
    font-size: 18px;
    text-decoration: none;
    color: black;
}

.link-soon{
    font-size: 16px;
    text-decoration: none;
    color: black;
}


@media only screen and (min-width: 700px) {
    .heading-soon{
        width: 600px
    }
}