.option-btn{
    text-align: center;
}

.stats{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.chart-row{
    text-align: center;
    padding-top: 100px;
    width: 100%;
}

.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown button */
.dropdown-button {
    padding: 4px;
    margin: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    background-color: black;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    /*-webkit-appearance: none; !* Reset default styling on iOS devices *!*/
}

/* Dropdown content */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Dropdown items */
.dropdown-item {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

/* Show the dropdown content when the button is clicked */
.dropdown:hover .dropdown-content {
    display: block;
}