.menu-title{
    text-align: center;
    font-size: 30px;
    padding-top: 100px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.menu-cat{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    padding: 50px 40px 20px;
    font-size: 24px;
}

.menu-title-dec{
    background-color: black;
    height: 2px;
    width: 30px;
    margin-top: -30px;
    left: 50%;
    position: absolute;
}

.menu-cols{
    justify-content: center;
    text-align: center;
}

.menu-cols1{
    display: flex;
    justify-content: center;
    text-align: center;
}

.menu-col1{
    width: 50%;
    height: 80%;
}

.menu-col2, .menu-col21{
    margin-top: 50px;
    width: 45%;
    height: 80%;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    white-space: pre-wrap;
}

.menu-col2{
    padding-top: 24px;
}

.menu-col21{
    padding-top: 58px;
}

.menu-item, .menu-item2, .menu-item3{
    padding-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.menu-item-center{
    margin-top: -18px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-weight: 700;
}


.menu-sizes{
    padding: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
    white-space: pre-wrap;
}


.menu-sizes2{
    padding-top: 15px;
    text-align: center;
    padding-right: 4px;
}

.menu-items{
    padding-top: 0px;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
}

.menu-items1{

}

.menu-decorator{
    height: 2px;
    background-color: black;
    width: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.menu-item1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    padding-left: 30px;
}

.menu-cont{
   height: 300px;
   border: 2px solid black;
    margin-right: 6%;
    margin-left: 6%;
    width: 88%;
    margin-top: 50px;
    position: relative;
}

.menu-socials{
    display: flex;
    justify-content: center;
}

.menu-socials-container{
    background-color: white;
    height: 54px;
    width: 54px;
    border-radius: 50%;
    border: 2px solid black;
    margin: 20px 20px;
    margin-top: 40px;
    cursor: pointer;
}

.menu-credits{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 15px;
    padding-top: 2%;
    margin-bottom: 20px;
    text-decoration: none;
}


@media only screen and (min-width: 700px) {
    .menu-row{
        display: flex;
        justify-content: center;
    }
    .menu-cont {
        height: 620px;
    }

    .menu-sizes0{
        padding-top: 36px;
    }

    .menu-sizes00{
        padding-top: 15px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 800px) {
    .menu-sizes0{
        padding-top: 56px;
    }

    .menu-sizes00{
        padding-top: 36px;
    }
}

@media only screen and (max-width: 700px) {
    .menu-row{
        display: block;
        justify-content: center;
    }

    .menu-sizes0{
        padding-top: 36px;
    }

    .menu-sizes00{
        padding-top: 15px;
    }

    .menu-cont {
        height: 650px;
    }
}