.conta-login-main{
    height: 1400px;
    position: absolute;
    background-color: black;
    width: 80%;
    padding-top: 20px;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.spatiu-conta{
    height: 100px;
}

.conta-title{
    color: white;
    text-align: center;
    padding-top: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 22px;
}

.conta-line{
    height: 1px;
    border: 1px solid white;
    width: 50px;
    margin-top: 15px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.conta-content{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 40px 5px 5px;
    text-align: center;
    position: relative;
    margin-top: -90px;
}

.padding-label-conta{
    padding: 5px;
}

.padding-label1-conta{
    padding:10px 62px 5px 5px;
}

.padding-label3-conta{
    padding:10px 35px 5px 5px;
}

.padding-label4-conta{
    padding:10px 66px 5px 5px;
}

.padding-label5-conta{
    padding:10px 30px 5px 5px;
}

.padding-label6-conta{
    padding:10px 50px 5px 5px;
}

.padding-label7-conta{
    padding:10px 51px 5px 5px;
}

.padding-label8-conta{
    padding:10px 17px 5px 5px;
}

.padding-label9-conta{
    padding:10px 30px 5px 5px;
}

.padding-label10-conta{
    padding:10px 30px 5px 5px;
}

.padding-label11-conta{
    padding:10px 72px 5px 5px;
}

.padding-label12-conta{
    padding:10px 52px 5px 5px;
}

.padding-label13-conta{
    padding:10px 41px 5px 5px;
}

.padding-label14-conta{
    padding:10px 28px 5px 5px;
}

.padding-label15-conta{
    padding:10px 28px 5px 5px;
}

.padding-label16-conta{
    padding:10px 70px 5px 5px;
}

.padding-label17-conta{
    padding:10px 38px 5px 5px;
}

.padding-label18-conta{
    padding:10px 54px 5px 5px;
}

.padding-label19-conta{
    padding:10px 55px 5px 5px;
}

.padding-label20-conta{
    padding:10px 18px 5px 5px;
}

.padding-label21-conta{
    padding:10px 50px 5px 5px;
}

.padding-label22-conta{
    padding:10px 35px 5px 5px;
}

.padding-label23-conta{
    padding:10px 31px 5px 5px;
}

.padding-label24-conta{
    padding:10px 20px 5px 5px;
}

.padding-label25-conta{
    padding:10px 33px 5px 5px;
}

.padding-label26-conta{
    padding:10px 32px 5px 5px;
}

.padding-label27-conta{
    padding:10px 20px 5px 5px;
}

.padding-label28-conta{
    padding:10px 45px 5px 5px;
}

.padding-label29-conta{
    padding:10px 45px 5px 5px;
}

.padding-label30-conta{
    padding:10px 45px 5px 5px;
}

.padding-label31-conta{
    padding:10px 45px 5px 5px;
}

.padding-label32-conta{
    padding:10px 45px 5px 5px;
}

.padding-label33-conta{
    padding:10px 45px 5px 5px;
}

.padding-label34-conta{
    padding:10px 45px 5px 5px;
}

.padding-label35-conta{
    padding:10px 45px 5px 5px;
}

.padding-label36-conta{
    padding:10px 45px 5px 5px;
}

.padding-label37-conta{
    padding:10px 45px 5px 5px;
}

.padding-labe38-conta{
    padding:10px 45px 5px 5px;
}

.padding-label39-conta{
    padding:10px 45px 5px 5px;
}

.padding-label40-conta{
    padding:10px 45px 5px 5px;
}

.padding-label41-conta{
    padding:10px 45px 5px 5px;
}

.padding-label42-conta{
    padding:10px 45px 5px 5px;
}

.padding-label43-conta{
    padding:10px 45px 5px 5px;
}

.padding-label44-conta{
    padding:10px 45px 5px 5px;
}

.padding-label45-conta{
    padding:10px 45px 5px 5px;
}

.padding-label46-conta{
    padding:10px 45px 5px 5px;
}

.margin-label-conta{
    margin: 5px;
}

.margin-label-submit-conta-main{
    margin: 25px 5px 5px 11px;
    padding: 5px 84px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    background-color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 700px) {

    .conta-login-main{
        height: 1500px;
    }


    .conta-content{
        margin-top: -120px;
    }

}