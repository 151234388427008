.conta-login{
    height: 500px;
    position: absolute;
    background-color: black;
    width: 90%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.no-wrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
}

.no-wrap-data{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    padding-bottom: 20px;
}

.conta-title-batistei{
    color: white;
    text-align: center;
    padding-top: 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 700;

}

.conta-line{
    height: 1px;
    border: 1px solid white;
    width: 50px;
    margin-top: 15px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}



.conta-content{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 40px 5px 5px;
    text-align: center;
    top: 55%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}

.conta-content-batistei{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    padding: 40px 5px 5px;
    text-align: center;
    top: 55%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}

.margin-label-conta{
    padding:10px 80px 5px 5px;
}

.label-conta-batistei-data{
    padding:10px 80px 5px 5px;
}

.label-conta-batistei-cafea{
    padding:10px 72px 5px 5px;
}

.label-conta-batistei-lapte{
    padding:10px 70px 5px 5px;
}

.label-conta-batistei-alt{
    padding:10px 30px 5px 5px;
}

.label-conta-batistei-cash{
    padding:10px 80px 5px 5px;
}

.label-conta-batistei-card{
    padding:10px 83px 5px 5px;
}

.margin-label-submit-conta{
    margin: 10px 5px 5px 11px;
    padding: 5px 84px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    background-color: white;
    border-radius: 5px;
}


@media only screen and (max-width: 700px) {
    .conta-login {
        height: 550px;
    }

    .conta-title-batistei{
        color: white;
        text-align: center;
        padding-top: 40px;
        margin-top: 20px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 28px;
    }
}



    .conta-line{
        height: 1px;
        border: 1px solid white;
        width: 50px;
        margin-top: 15px;
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

    }


    .conta-content-batistei{
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 22px;
        padding: 40px 2px 2px;
        text-align: center;
        top: 53%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
    }


    .margin-label-conta{
        margin-top: 15px;
        padding: 4px 10px;
    }

    .margin-label-conta0{
        margin: 5px;
        padding: 3px 20px;
    }

    .margin-label-submit-conta{
        margin: 10px 5px 5px 11px;
        padding: 5px 84px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 18px;
        background-color: white;
        border-radius: 5px;
        color: black;
    }

    input[type="date"] {
        /* Add your iOS-specific styles here */
        padding: 0px 55px 5px 5px;
        color: black;
    }

@media screen and (-webkit-min-device-pixel-ratio: 2) {
    /* Target iOS devices */
    input[type="date"] {
        /* Add your iOS-specific styles here */
        padding: 0px 55px 5px 5px;
        color: black;
    }
}

@media screen and (-webkit-device-pixel-ratio: 2) and (max-width: 375px)  {
    /* Target iOS devices */
    input[type="date"] {
        /* Add your iOS-specific styles here */
        padding: 0px 55px 5px 5px;
        color: black;
    }
}


