.login-offset{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
}

.login-content-conta{
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    padding: 40px 10px 10px;
    text-align: center;
    top: 55%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
}

.login-btn{
    width: 150px;
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 15px;
    font-size: 18px;
    color: black;
    background-color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.margin-label-login{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: black;
    font-size: 20px;
    padding: 5px 22px;
    margin: 5px;
}