.logo-small{
    display: none;
}

.link-nav{
    text-decoration: none;
}

.blank{
    display: none;
}

.scroll-to-top{
    transition: opacity 1s ease;
}

.menu-options{
    list-style-type: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border-bottom: 2px solid black;
    background-color: black;
    position: fixed;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.nav-main{
    position: fixed;
    z-index: 2;
    transition: opacity 1s ease;
}

.menu-option{
    padding: 10px;
    color: white;
}

.menu-button{
    height: 30px;
    z-index: 2;
}



@media only screen and (min-width: 700px) {

    .scroll-to-top{
        display: none;
    }

    .arrow{
        display: none;
    }

    .menu-options{
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-between;
    }

    .button-section{
        display: none;
    }

    .menu-links{
        display: flex;
        justify-content: space-between;
    }

}

@media only screen and (max-width: 700px) {

    .scroll-to-top{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: white;
        position: fixed;
        top: 85%;
        right: 8%;
        z-index: 3;
        border: 2px solid black;
        text-align: center;
    }

    .arrow{
        margin-top: 5px;
        height: 36px;
        width: 36px;
        object-fit: contain;
    }


    .menu-options{
        display: inline-block;
        padding: 20px 0;
        width: 100%;
        font-size: 20px;
        top: 45px;
        z-index: 1;
        height: 95%;
    }

    .menu-links{
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .nav-menu{
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .inactive{
        display: none;
    }


    .menu-button{
        float: right;
        margin-top: 6px;
        padding-right: 10px;
    }

    .menu-logo-mobile{
        float: left;
        height: 20px;
        margin-top: 12px;
        padding-left: 10px;
    }

    .button-section{
        float: right;
        height: 45px;
        background-color: white;
        width: 100%;
        position: fixed;
        border-bottom: 2px solid black;
    }

}