.locatii-title{
    font-size: 38px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.locatii-decorator{
    height: 2px;
    background-color: black;
    width: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.locatii-div{
    justify-content: center;
    height: 1500px;
}

.container{
    border: 2px black solid;
    height: 610px;
    margin: 10%;
    position: relative;
}

.text-cont{
    margin: 35px 10% 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.map-cont{
    margin: 40px 8%;
    border: 2px black solid;
    height: 350px;
}

.text-cont{
    text-align: center;
    width: 80%;
}

.text-loc{
    padding: 3px;
}

@media only screen and (max-width: 700px) {

    .locatii-title{
        font-size: 30px;
    }

    .locatii-decorator{
        width: 8%;
    }

}